<template>
    <div>
        <!-- 정보입력 -->
        <!-- 정보입력: 일반 -->
        <div class="mb-6 mb-md-10">
            <p class="font-size-14 text-right grey-a--text"><span style="color: rgb(255, 0, 0)">*</span> Required entry</p>
        </div>
        <div class="table-style table-style--default">
            <div class="table-style__row">
                <div class="table-style__th">Username<span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <form-email-input v-model="user.username" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">Password <span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.password" @input="input" type="password" name="password" outlined hide-details />
                    <div class="font-size-12 grey--text text--darken-1 mt-8 mb-n8">Password must have<br/>- an alphabet character<br/>- length between 8 to 16<br/>- one or more of these characters: ~!@#$%^&*()_+<>?:{}</div>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">Password Re-type<span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.passwordConfirm" @input="input" type="password" name="password2" outlined :rules="[() => (user.passwordConfirm && user.passwordConfirm == user.password) || 'Password does not match']" class="mt-n10 mb-n20" />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">Name <span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <form-name-input v-model="user" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">Street Address</div>
                <div class="table-style__td">
                    <v-text-field v-model="user.address.street" @input="input" type="text" name="street" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">City</div>
                <div class="table-style__td">
                    <v-text-field v-model="user.address.city" @input="input" type="text" name="city" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">State/Province</div>
                <div class="table-style__td">
                    <v-text-field v-model="user.address.state" @input="input" type="text" name="state" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">Zip/Postal Code</div>
                <div class="table-style__td">
                    <v-text-field v-model="user.address.zip" @input="input" type="text" name="zip" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">Country <span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <form-zone-input v-model="user" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">E-mail Address<span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <form-email-input v-model="user.email" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">TEL<span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <form-phone-input v-model="user" />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">Speciality <span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <v-select v-model="user.occupation" :items="occupations" @input="input" name="occupation" outlined hide-details />
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">Business License Number <span style="color: #ff0000">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.businessLicenseNumber" @input="input" name="businessLicenseNumber" outlined messages="Fill in N/A, if you do not have the number" class="mt-n10 mb-n20" />
                </div>
            </div>
        </div>

        <!-- 하단버튼 -->
        <div class="v-btn-group--bottom">
            <v-row justify="center" class="row--small">
                <v-col cols="6" sm="auto">
                    <v-btn block color="secondary" class="w-md-200px" @click="submit()">Submit</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { PERSON_OCCUPATIONS } from "@/assets/variables";

import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import FormPhoneInput from "./form-phone-input.vue";
import FormNameInput from "./form-name-input.vue";
import FormZoneInput from "./form-zone-input.vue";
import FormEmailInput from "./form-email-input.vue";

const occupations = Object.values(PERSON_OCCUPATIONS);

export default {
    components: {
        KcpCert,
        DaumPostcode,
        FormPhoneInput,
        FormNameInput,
        FormZoneInput,
        FormEmailInput,
    },
    props: ["value"],
    data() {
        return {
            user: this.$props.value || {},

            occupations,
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        "user.username"(username) {
            this.user.email = username;
        },
    },
    methods: {
        async init() {
            //
        },
        input() {
            this.$emit("input", {
                ...this.user,
            });
        },
        cancel() {
            this.$router.go(-1);
        },

        submit() {
            if (this.validate()) this.$emit("submit", this.user);
        },

        certify() {
            this.$refs["kcp-cert"].auth();
        },

        verify(payload) {
            this.user._certification = payload._certification;
            var [phone1, phone2, phone3] = payload.phone?.phoneNumberFormat()?.split("-");
            this.user.phone1 = phone1;
            this.user.phone2 = phone2;
            this.user.phone3 = phone3;
        },

        validate() {
            try {
                if (!this.user.username) throw new Error("Please input your USERNAME");
                if (!this.user.password) throw new Error("Please input your PASSWORD");
                if (!this.checkPassword(this.user.password)) return false;
                if (!this.user.passwordConfirm) throw new Error("Please re-type your PASSWORD");
                if (this.user.password != this.user.passwordConfirm) throw new Error("PASSWORD does not match");

                if (!this.user.name_title) throw new Error("Please fill in your TITLE");
                if (!this.user.name_first) throw new Error("Please fill in your FIRST NAME");
                if (!this.user.name_last) throw new Error("Please fill in your LAST NAME");
                // if (!this.user.name) throw new Error("We need your NAME to answer your inquiries");

                if (!this.user._zone && this.user._country) throw new Error("We need your REGION to answer your inquires");
                if (!this.user._zone) throw new Error("We need your COUNTRY to answer your inquires");

                if (!this.user.email) throw new Error("We need your EMAIL to answer your inquiries");

                if (!this.user.phone_country_code) throw new Error("We need your TELEPHONE COUNTRY CODE to answer your inquires");
                if (!this.user.phone_number) throw new Error("We need your TELEPHONE NUMBER to answer your inquires");
                // if (!this.user.phone) throw new Error("We need your TELEPHONE NUMBER to answer your inquires");

                if (!this.user.occupation) throw new Error("We need your SPECIALITY to answer your inquires");

                if (!this.user.businessLicenseNumber) throw new Error("Please, Fill in N/A, If you do not have the number");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("Password must have one or more of these characters: ~!@#$%^&*()_+|<>?:{}");
            if (!checkEng.test(password)) throw new Error("Password must have a alphabet character");
            if (!checkNum.test(password)) throw new Error("Password must have a number character");

            if (password.length < 8 || 16 < password.length) throw new Error("Password must have length between 8 to 16");

            return true;
        },
    },
};
</script>
