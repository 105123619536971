var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(1), _c('div', {
    staticClass: "table-style__td"
  }, [_c('form-email-input', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(2), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "name": "password",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  }), _vm._m(3)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(4), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    staticClass: "mt-n10 mb-n20",
    attrs: {
      "type": "password",
      "name": "password2",
      "outlined": "",
      "rules": [function () {
        return _vm.user.passwordConfirm && _vm.user.passwordConfirm == _vm.user.password || 'Password does not match';
      }]
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.passwordConfirm,
      callback: function ($$v) {
        _vm.$set(_vm.user, "passwordConfirm", $$v);
      },
      expression: "user.passwordConfirm"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(5), _c('div', {
    staticClass: "table-style__td"
  }, [_c('form-name-input', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Street Address")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "street",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.address.street,
      callback: function ($$v) {
        _vm.$set(_vm.user.address, "street", $$v);
      },
      expression: "user.address.street"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("City")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "city",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.address.city,
      callback: function ($$v) {
        _vm.$set(_vm.user.address, "city", $$v);
      },
      expression: "user.address.city"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("State/Province")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "state",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.address.state,
      callback: function ($$v) {
        _vm.$set(_vm.user.address, "state", $$v);
      },
      expression: "user.address.state"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Zip/Postal Code")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "name": "zip",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.address.zip,
      callback: function ($$v) {
        _vm.$set(_vm.user.address, "zip", $$v);
      },
      expression: "user.address.zip"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(6), _c('div', {
    staticClass: "table-style__td"
  }, [_c('form-zone-input', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(7), _c('div', {
    staticClass: "table-style__td"
  }, [_c('form-email-input', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(8), _c('div', {
    staticClass: "table-style__td"
  }, [_c('form-phone-input', {
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(9), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.occupations,
      "name": "occupation",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.occupation,
      callback: function ($$v) {
        _vm.$set(_vm.user, "occupation", $$v);
      },
      expression: "user.occupation"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_vm._m(10), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    staticClass: "mt-n10 mb-n20",
    attrs: {
      "name": "businessLicenseNumber",
      "outlined": "",
      "messages": "Fill in N/A, if you do not have the number"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.businessLicenseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.user, "businessLicenseNumber", $$v);
      },
      expression: "user.businessLicenseNumber"
    }
  })], 1)])]), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-200px",
    attrs: {
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-6 mb-md-10"
  }, [_c('p', {
    staticClass: "font-size-14 text-right grey-a--text"
  }, [_c('span', {
    staticStyle: {
      "color": "rgb(255, 0, 0)"
    }
  }, [_vm._v("*")]), _vm._v(" Required entry")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Username"), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Password "), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-size-12 grey--text text--darken-1 mt-8 mb-n8"
  }, [_vm._v("Password must have"), _c('br'), _vm._v("- an alphabet character"), _c('br'), _vm._v("- length between 8 to 16"), _c('br'), _vm._v("- one or more of these characters: ~!@#$%^&*()_+<>?:{}")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Password Re-type"), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Name "), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Country "), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("E-mail Address"), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("TEL"), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Speciality "), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("Business License Number "), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]);

}]

export { render, staticRenderFns }